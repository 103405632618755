* {
  margin: 0;
  padding: 0;
}

html,
body {
  font-family: "Poppins", sans-serif;
  background-color: #121212;
}

body {
  overflow-x: hidden;
}

.webgl {
  filter: blur(5px) saturate(50%);
  position: absolute;
  top: 0;
  left: 0;
  outline: none;
  mix-blend-mode: exclusion;
}

.hero {
  height: 100vh;
  display: grid;
  place-items: center;
}

.hero-text {
  font-size: 8vw;
  text-transform: uppercase;
  color: white;
  text-align: center;
  font-weight: bold;
}

section {
  height: 100vh;
}
